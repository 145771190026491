import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, IconButton, Divider, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Phone, Email, LocationOn } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import SocialLinks from './SocialLinks';

const FooterBox = styled(Box)(({ theme }) => ({
  color: 'white',
  borderTop: '10px solid gold',
  borderTopStyle: 'groove',
  padding: theme.spacing(6),
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(4),
    textAlign: 'left',
  },
  textAlign: 'center',
  backgroundColor: '#121212',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#1c1c1c',
  },
}));

const FooterSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    textAlign: 'left',
  },
}));

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
  },
}));

const ContactLink = styled(Typography)(({ theme }) => ({
  ml: 2,
  color: 'white',
  textDecoration: 'none',
  transition: 'color 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    color: 'gold',
    transform: 'scale(0.85)',
  },
}));

const CustomDivider = styled(Divider)(({ theme }) => ({
  backgroundColor: 'gold',
  width: '50%',
  margin: `${theme.spacing(2)} auto`,
  [theme.breakpoints.down('sm')]: {
    marginLeft: 0,
    width: '100%',
  },
}));

const ServiceLink = styled(Link)(({ theme }) => ({
  color: 'white',
  textDecoration: 'none',
  transition: 'color 0.3s ease-in-out, transform 0.3s ease-in-out',
  '&:hover': {
    color: 'gold',
    transform: 'scale(1.05)',
  },
}));

const Footer = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const cities = ["Chesapeake", "Hampton", "Newport News", "Norfolk", "Portsmouth", "Suffolk", "Virginia Beach"];

  return (
    <FooterBox>
      <Grid container spacing={4} justifyContent="center" alignItems="stretch">
        <Grid item xs={12} sm={4}>
          <FooterSection>
            <FooterTitle variant="h6">Contact Us</FooterTitle>
            <CustomDivider />
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <IconButton color="inherit" sx={{ p: 0 }} component="a" href="tel:+17575591231" target="_blank">
                <Phone />
              </IconButton>
              <ContactLink variant="body2" component="a" href="tel:+17575591231" target="_blank">(757)-559-1231</ContactLink>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <IconButton color="inherit" sx={{ p: 0 }} component="a" href="mailto:info@allpcrepair.com" target="_blank">
                <Email />
              </IconButton>
              <ContactLink variant="body2" component="a" href="mailto:info@allpcrepair.com" target="_blank">info@allpcrepair.com</ContactLink>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
              <IconButton color="inherit" sx={{ p: 0 }} component="a" href="https://www.google.com/maps/place/Hampton+Roads,+VA" target="_blank">
                <LocationOn />
              </IconButton>
              <ContactLink variant="body2" component="a" href="https://www.google.com/maps/place/Hampton+Roads,+VA" target="_blank">
                Hampton Roads, Virginia
              </ContactLink>
            </Box>
            <Typography variant="body2" sx={{ mt: 1 }}>
              {cities.join(', ')}
            </Typography>
          </FooterSection>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FooterSection>
            <FooterTitle variant="h6">Our Services</FooterTitle>
            <CustomDivider />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ServiceLink to="/services/it-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>IT Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/it-services/learn-more">
                  <Typography variant="body2" sx={{ mb: 1 }}>Detailed Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/cloud-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Cloud Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/cybersecurity-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Cybersecurity Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/technology-procurement-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Technology Procurement</Typography>
                </ServiceLink>
                <ServiceLink to="/services/voip-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>VoIP Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/web-hosting-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Web Hosting</Typography>
                </ServiceLink>
                <ServiceLink to="/services/computer-repair-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Computer Repair Services</Typography>
                </ServiceLink>
              </Grid>
              <Grid item xs={6}>
                <ServiceLink to="/services/network-setup-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Network Setup Services</Typography>
                </ServiceLink>
               
                <ServiceLink to="/services/managed-it-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Managed IT Services</Typography>
                </ServiceLink>
                <ServiceLink to="/services/cybersecurity-services/risk-assessment">
                  <Typography variant="body2" sx={{ mb: 1 }}>Cyber Security Risk Assessment</Typography>
                </ServiceLink>
                <ServiceLink to="/services/cybersecurity-services/virtual-ciso">
                  <Typography variant="body2" sx={{ mb: 1 }}>Virtual CISO</Typography>
                </ServiceLink>
                <ServiceLink to="/services/cybersecurity-services/cybersecurity-management">
                  <Typography variant="body2" sx={{ mb: 1 }}>Cybersecurity Management</Typography>
                </ServiceLink>
                <ServiceLink to="/services/voip-services/voip">
                  <Typography variant="body2" sx={{ mb: 1 }}>VoIP Services Management</Typography>
                </ServiceLink>
                <ServiceLink to="/services/technology-procurement-management/technology-procurement-services">
                  <Typography variant="body2" sx={{ mb: 1 }}>Technology Procurement Management</Typography>
                </ServiceLink>
                <ServiceLink to="/services/datacenter-services/datacenter-services-management">
                  <Typography variant="body2" sx={{ mb: 1 }}>Data Center Services</Typography>
                </ServiceLink>
              </Grid>
            </Grid>
          </FooterSection>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FooterSection>
            <FooterTitle variant="h6">Current Time</FooterTitle>
            <CustomDivider />
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ mb: 1 }}>
             
              <Typography variant="body2" >
                {currentTime.toLocaleTimeString()}
              </Typography>
            </Box>
            <Typography variant="body2">{currentTime.toLocaleDateString()}</Typography>
          </FooterSection>
        </Grid>
      </Grid>
      <Typography variant="body2" sx={{ mt: 4 }}>
        &copy; {new Date().getFullYear()} All PC Repair VA. All rights reserved. | <Link to="/privacy-policy" style={{ color: 'white', textDecoration: 'none' }}><Button sx={{ color: 'white', transition: 'color 0.3s ease-in-out, transform 0.3s ease-in-out', '&:hover': { color: 'gold', transform: 'scale(1.05)' } }}>Privacy Policy</Button></Link>
      </Typography>
     <SocialLinks />
    </FooterBox>
  );
};

export default Footer;
