import React from 'react';
import HeroSection from '../components/HeroSection';
import ServicesSection from '../components/ServicesSection';
import TailoredServices from '../components/TailoredServices';
import AlertNewsBanner from '../components/AlertNewsBanner';
import OverviewSection from '../components/OverViewSection';
import MyDivider from '../components/MyDivider';


const Home = () => {

  return (
    <div className="white-bg">
      <HeroSection
      
        backgroundImage="/bg.png"
        mainText="IT and Cybersecurity Services For Your Business"
        subText="Managed IT Services, Cloud Services, and more"
        buttonText="Explore Our Services"
        buttonRoute="/services"
      />
      <AlertNewsBanner />
      <TailoredServices />
      <MyDivider />
    <OverviewSection />
   
     <ServicesSection />
     
    </div>
  );
};

export default Home;
