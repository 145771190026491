import Divider from '@mui/material/Divider';

function MyDivider() {
  return (
    <Divider 
      sx={{ 
        backgroundColor: '#ffd700',
        height: 2, 
        margin: '20px 0',
        borderRadius: 1,
      }} 
    />
  );
}
export default MyDivider;