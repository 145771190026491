import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Cloud, Security, Build } from '@mui/icons-material';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const HeroBox = styled(Box)(({ theme, backgroundImage }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '50vh',
  background: `url(${backgroundImage}) no-repeat center center/cover`,
  color: 'white',
  textAlign: 'center',
  padding: '0 20px',
  [theme.breakpoints.down('md')]: {
    minHeight: '50vh',
    padding: '40px 20px',
  },
}));

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
  marginTop: '20px',
  [theme.breakpoints.down('md')]: {
    gap: '10px',
  },
}));

const HeroSection = ({ backgroundImage, mainText, subText, buttonText, buttonRoute }) => {
  return (
    <HeroBox backgroundImage={backgroundImage}>
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
      >
        <Box>
          <Typography 
            variant="h2" 
            component="h1" 
            gutterBottom 
            sx={{ 
              fontSize: { xs: '2rem', sm: '3rem', md: '4rem' },
              lineHeight: 1.2,
              wordBreak: 'break-word'
            }}
          >
            {mainText}
          </Typography>
          <Typography 
            variant="h5" 
            component="h2" 
            sx={{ 
              mt: 2, 
              mb: 4, 
              fontSize: { xs: '1rem', sm: '1.25rem', md: '1.5rem' } 
            }}
          >
            {subText}
          </Typography>
          <IconBox>
            <Cloud fontSize="large" />
            <Security fontSize="large" />
            <Build fontSize="large" />
          </IconBox>
          <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <Button
              variant="contained"
              sx={{ 
                mt: 4, 
                backgroundColor: '#FFD700', 
                color: '#000',
                '&:hover': {
                  backgroundColor: '#FFC107',
                },
              }}
              component={Link}
              to={buttonRoute}
            >
              {buttonText}
            </Button>
          </motion.div>
        </Box>
      </motion.div>
    </HeroBox>
  );
};

export default HeroSection;
